/**
 * Define the theme and its types, to be used with styled-components
 */

// A convenient way to map object keys to the Theme interface. tldr; we get
// ts suggestions for theme items with styled-components
type ThemeObject<O> = { [K in keyof O]: O[K] };

const colors: any = {
  white: "#ffffff",
  black: "#000000",
  dark: "rgb(7,31,42)",
  light: "rgb(253,235,211)",
  red: "rgb(220,60,50)"
};

// Basic modular scale helper. Once primed with a exponent, the generated fn can
// be used to scale by a factor.
const ms = (exp: number) => (factor: number) => Math.pow(exp, factor);
// The primed version can be used to walk the 1.414 modular scale...
const primedMs = ms(1.414);

const fonts = {
  primary: {
    family: "sabre",
    fallback: "serif",
    weights: {
      normal: "normal",
      medium: 600
    }
  },
  heading: {
    family: "capo",
    fallback: "serif",
    weights: {
      normal: 700,
      medium: 900
    }
  }
};

export interface Theme {
  ms: (f: number) => number;
  msrem: (f: number) => string;
  colors: ThemeObject<typeof colors>;
  fonts: ThemeObject<typeof fonts>;
}

const theme: Theme = {
  colors,
  fonts,
  ms: primedMs,
  msrem: f => `${primedMs(f)}rem`
};

export { theme };
