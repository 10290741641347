import { styled } from "../../styled";

export type HomePageProps = {
  ref?: any;
};
export const HomePageIntroFrame = styled.section<HomePageProps>`
  display: inline-flex;
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 83px;
  background: ${({ theme }) => theme.colors.red};
`;

export const DogLogo = styled.img`
  min-height: 230px;
  height: 35vh;
`;

export const Title = styled.div`
  font-family: capo, serif;
  font-weight: 700;
  font-size: ${({ theme }) => theme.msrem(3)};
  color: ${({ theme }) => theme.colors.light};
  margin-top: ${({ theme }) => theme.msrem(3)};
`;

export const SubTitle = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.msrem(3)};
  font-size: 2.2vmin;
  font-weight: 900;
  letter-spacing: 0.2rem;
  color: ${({ theme }) => theme.colors.light};
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -0.7rem;
    margin: auto;
    width: 10vmin;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const ContactFrame = styled(HomePageIntroFrame)`
  background: ${({ theme }) => theme.colors.light};

  ${Title},
  ${SubTitle} {
    color: ${({ theme }) => theme.colors.red};
  }

  ${SubTitle} {
    &:before {
      background-color: ${({ theme }) => theme.colors.red};
    }

    a {
      color: ${({ theme }) => theme.colors.red};
      text-decoration: none;
      display: block;
    }
  }
`;

export const CherLogo = styled.img`
  min-height: 230px;
  height: 35vh;
`;

export type ContactProps = {
  ref?: any;
};
export const ContactWrap = styled.div<ContactProps>``;
