import React, { useRef, useEffect, useCallback } from "react";
import { ProjectWrap } from "./styled";
import { useData } from "contexts/useData";

interface ProjectProps {
  title: string;
  media: any;
  href?: string;
  background?: string;
}

export const Project: React.FC<ProjectProps> = ({
  title,
  media,
  href,
  background
}) => {
  const projectRenderer = (
    <>
      {media.type === "image" && (
        <img
          src={`/${media.type}/projects/${title
            .split(" ")
            .join("-")
            .toLowerCase()}/${media.src}`}
          alt={media.title}
        />
      )}
      {media.type === "video" && (
        <video controls>
          <source
            src={`/${media.type}/projects/${title
              .split(" ")
              .join("-")
              .toLowerCase()}/${media.src}`}
            type="video/mp4"
          />
        </video>
      )}
    </>
  );

  // useEffect(() => {
  //   window.addEventListener("scroll", setNav);
  //   setNav();

  //   return () => {
  //     window.removeEventListener("scroll", setNav);
  //   };
  //   //eslint-disable-next-line
  // }, []);

  // const setNav = useCallback(() => {
  //   if (projectContainer) {
  //     const top = projectContainer.current!.getBoundingClientRect().top;
  //     const bottom = projectContainer.current!.getBoundingClientRect().bottom;

  //     if (
  //       (top >= 0 && top <= 40) ||
  //       (bottom >= 0 && bottom <= window.innerHeight)
  //     ) {
  //       setNavTheme(media.navTheme);
  //     }
  //   }
  // }, [setNavTheme, media.navTheme]);

  return (
    <>
      {!href && (
        <ProjectWrap
          className={`${media.width} ${
            media.imageSize ? media.imageSize : "contain"
          }`}
          background={background!}
        >
          {projectRenderer}
        </ProjectWrap>
      )}
      {href && (
        <ProjectWrap
          as="a"
          href={href}
          title={`View ${title}`}
          className={`${media.width} ${
            media.imageSize ? media.imageSize : "contain"
          }`}
          background={background!}
        >
          {projectRenderer}
        </ProjectWrap>
      )}
    </>
  );
};
