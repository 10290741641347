import React, { useEffect } from "react";
import { ShopPageFrame, Title, SubTitle } from "./styled";
import { useData } from "../../contexts/useData";

interface ShopPageProps {}

export const ShopPage: React.FC<ShopPageProps> = () => {
  const { setNavTheme } = useData();

  useEffect(() => {
    setNavTheme("light");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ShopPageFrame data-nav-theme="light">
        <Title>MIKESIAN</Title>
        <SubTitle>SHOP</SubTitle>
      </ShopPageFrame>
    </>
  );
};
