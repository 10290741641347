import React, { useEffect } from "react";
import Contact from "../../components/Contact";
import { useData } from "contexts/useData";

interface ContactProps {}

export const ContactPage: React.FC<ContactProps> = () => {
  const { setNavTheme } = useData();

  useEffect(() => {
    setNavTheme("dark");

    // eslint-disable-next-line
  }, []);

  return <Contact />;
};
