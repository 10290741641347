import baseStyled, {
  ThemedStyledInterface,
  css as baseCss,
  BaseThemedCssFunction
} from "styled-components";
import { Theme } from "./theme";

// Wrap styled type with our Theme. This makes it so that typescript can
// introspect our Theme type
const styled = baseStyled as ThemedStyledInterface<Theme>;
const css = baseCss as BaseThemedCssFunction<Theme>;

export { styled, css };
