import React from "react";
import Projects from "components/Projects";

interface MotionProps {}

export const MotionPage: React.FC<MotionProps> = () => {
  return (
    <Projects
      category={"motion"}
      projectOrder={["Manchester Psych Fest"]}
      links={false}
    />
  );
};
