import { styled, css } from "../../styled";
import { Link, LinkProps } from "react-router-dom";

interface LogoWrapProps extends LinkProps {
  navThemeProp?: string;
}

export const LogoWrap = styled(Link)<LogoWrapProps>`
  display: flex;
  font-family: capo, serif;
  font-weight: 700;
  font-size: ${({ theme }) => theme.msrem(1)};
  text-decoration: none;

  ${props =>
    props.navThemeProp === "light" &&
    css`
      color: ${({ theme }) => theme.colors.light};
    `};

  ${props =>
    props.navThemeProp === "dark" &&
    css`
      color: ${({ theme }) => theme.colors.dark};
    `};
`;
