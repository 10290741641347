import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./components/Root";

import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles } from "./styled";
import { DataProvider } from "./contexts/useData";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <DataProvider>
      <GlobalStyles />
      <Root />
    </DataProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
