import { styled } from "../../styled";
import devices from "styled/devices";

export type ProjectWrapProps = {
  background: string;
  imageSize?: string;
  href?: string;
  ref?: any;
};

export const ProjectWrap = styled.div<ProjectWrapProps>`
  width: 100vw;
  display: inline-flex;
  padding: 6rem 3rem;
  justify-content: center;
  background-color: ${({ theme, background }) => theme.colors[background]};
  float: left;

  ${devices.notebook`
    height: 100vh;
    &.half {
      width: 50vw;
    }
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  video {
    width: auto;
    height: 100%;
    outline: none;
    max-width: 100%;
  }

  &.fill {
    padding: 0;
    img {
      object-fit: cover;
    }
  }
`;
