import React, { createContext, useContext, useState } from "react";
import projectData from "../data/projects.json";

interface DataProps {
  children: React.ReactNode;
}

interface Data {
  navTheme: any;
  projects: any;
  setNavTheme: any;
}

export const DataContext = createContext({} as Data);
export const useData = () => useContext(DataContext);

export const DataProvider: React.FC<DataProps> = ({ children }) => {
  const [navTheme, setNavTheme] = useState("light");
  const [projects] = useState(projectData.projects);

  const configObject = {
    navTheme,
    projects,
    setNavTheme
  };

  return (
    <DataContext.Provider value={configObject}>{children}</DataContext.Provider>
  );
};
