import React from "react";
import { Title, SubTitle, ContactFrame, CherLogo } from "./styled";

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  return (
    <ContactFrame>
      <CherLogo
        src={"/image/svg/contact_cher.svg"}
        alt="Like A Dog With Two Tails"
      />
      <Title>CONTACT</Title>
      <SubTitle>
        <a href="mailto:hello@mikesian.studio">HELLO@MIKESIAN.STUDIO</a>
        DESIGN &amp; ART
        <a href="tel:+447715695555">+44 7715 695555</a>
      </SubTitle>
    </ContactFrame>
  );
};

export default Contact;
