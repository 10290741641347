import * as React from "react";
import { Nav, NavLinkDescriptor } from "../Nav";
import { PageMain, PageWrapper } from "./styled";

/**
 * A page wrapper to give us more control over any pages we add
 */

interface PageProps {
  children: React.ReactNode;
  className?: string;
}

const navLeft: NavLinkDescriptor[] = [
  {
    label: "Design",
    href: "design"
  },
  {
    label: "Art",
    href: "art"
  },
  {
    label: "Motion",
    href: "motion"
  }
];

const navRight: NavLinkDescriptor[] = [
  {
    label: "About",
    href: "about"
  },
  {
    label: "Shop",
    href: "shop"
  },
  {
    label: "Contact",
    href: "contact"
  }
];

const Page: React.FC<PageProps> = ({ children, className }) => {
  return (
    <PageWrapper className={className}>
      <Nav linksLeft={navLeft} linksRight={navRight} />
      <PageMain>{children}</PageMain>
    </PageWrapper>
  );
};

export default Page;
