import { createGlobalStyle } from "styled-components";
import { Theme } from "./theme";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  ${reset}
  
  @font-face {
    font-family: 'capo';
    src: url('/fonts/capo_w00_black-webfont.woff2') format('woff2'),
    url('/fonts/capo_w00_black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'capo';
    src: url('/fonts/capo_w00_bold-webfont.woff2') format('woff2'),
    url('/fonts/capo_w00_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'sabre';
    src: url('/fonts/sabre_w90_bold-webfont.woff2') format('woff2'),
    url('/fonts/sabre_w90_bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'sabre';
    src: url('/fonts/sabre_w90_regular-webfont.woff2') format('woff2'),
    url('/fonts/sabre_w90_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-family: ${({ theme }) =>
      `${theme.fonts.primary.family}, ${theme.fonts.primary.fallback}`};
    font-weight: ${({ theme }) => theme.fonts.primary.weights.normal};
    font-size: 125% /* 20px */;
    color: white;
  }

  body {
    background: ${({ theme }) => theme.colors.black};
    line-height: 1.3;
  }

  a {
    color: white;
  }
`;

export default GlobalStyles;
