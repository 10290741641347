import * as React from "react";
import { NavFrame, NavLinks, NavigationLink } from "./styled";
import Logo from "../Logo";
import { useData } from "../../contexts/useData";

export type NavLinkDescriptor = {
  label: string;
  href: string;
};

interface NavProps {
  linksLeft: NavLinkDescriptor[];
  linksRight: NavLinkDescriptor[];
}

export const Nav: React.FC<NavProps> = ({ linksLeft, linksRight }) => {
  const { navTheme } = useData();

  return (
    <NavFrame>
      <NavLinks>
        {linksLeft.map((nl, i) => (
          <NavigationLink
            activeClassName="is-active"
            navThemeProp={navTheme}
            to={`/${nl.href}`}
            key={i}
          >
            {nl.label}
          </NavigationLink>
        ))}
      </NavLinks>
      <Logo />
      <NavLinks>
        {linksRight.map((nl, i) => (
          <NavigationLink
            activeClassName="is-active"
            navThemeProp={navTheme}
            to={nl.href}
            key={i}
          >
            {nl.label}
          </NavigationLink>
        ))}
      </NavLinks>
    </NavFrame>
  );
};
