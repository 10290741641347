/**
 * The root component will contian any global logic, and handle routing (when
 * we need it)
 */

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import {
  AboutPage,
  ArtPage,
  ContactPage,
  DesignPage,
  HomePage,
  MotionPage,
  ShopPage
} from "../../pages";
import { ProjectDetail } from "components/ProjectDetail";

import { PageFrame } from "./styled";

export interface RootProps {}

export const Root: React.FC<RootProps> = () => {
  return (
    <Router>
      <PageFrame>
        <Switch>
          <Route exact={true} path="/">
            <HomePage />
          </Route>

          <Route exact={true} path="/design">
            <DesignPage />
          </Route>

          <Route
            exact={true}
            path="/design/:project"
            component={ProjectDetail}
          />

          <Route exact={true} path="/art">
            <ArtPage />
          </Route>

          <Route exact={true} path="/art/:project" component={ProjectDetail} />

          <Route exact={true} path="/motion">
            <MotionPage />
          </Route>

          <Route exact={true} path="/about">
            <AboutPage />
          </Route>

          <Route exact={true} path="/shop">
            <ShopPage />
          </Route>

          <Route exact={true} path="/contact">
            <ContactPage />
          </Route>
        </Switch>
      </PageFrame>
    </Router>
  );
};
