import { styled } from "../../styled";
import devices from "styled/devices";

export type ProjectWrapProps = {
  background?: string;
  imageSize?: string;
};

export const ProjectWrap = styled.div<ProjectWrapProps>`
  width: 100vw;
  height: 100vh;
  display: inline-flex;
  padding: 6rem 3rem;
  justify-content: center;
  background-color: ${props => props.background || "#000"};

  ${devices.notebook`
    &.half {
      width: 50vw;
    }
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  video {
    width: auto;
    height: 100%;
    outline: none;
  }

  &.fill {
    padding: 0;
    img {
      object-fit: cover;
    }
  }
`;

export const ProjectHeaderWrap = styled(ProjectWrap)``;

export type ProjectDescriptionWrapProps = {
  background?: string;
  color?: string;
};

export const ProjectDescriptionWrap = styled.div<ProjectDescriptionWrapProps>`
  color: ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.dark};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.fonts.primary.weights.normal};
  float: left;
  width: 100%;
`;

export const ProjectDescription = styled.div`
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 6rem 1.5rem;
`;
