import React, { useEffect } from "react";
import { AboutPageFrame, Title, SubTitle } from "./styled";
import { useData } from "../../contexts/useData";

interface AboutPageProps {}

export const AboutPage: React.FC<AboutPageProps> = () => {
  const { setNavTheme } = useData();

  useEffect(() => {
    setNavTheme("light");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AboutPageFrame data-nav-theme="light">
        <Title>MIKESIAN</Title>
        <SubTitle>ABOUT &amp; US</SubTitle>
      </AboutPageFrame>
    </>
  );
};
