import React, { useEffect, useCallback, useRef } from "react";
import {
  HomePageIntroFrame,
  ContactWrap,
  DogLogo,
  Title,
  SubTitle
} from "./styled";
import Projects from "components/Projects";
import Contact from "components/Contact";
import { useData } from "contexts/useData";

interface HomeProps {}

export const HomePage: React.FC<HomeProps> = () => {
  const { setNavTheme } = useData();

  useEffect(() => {
    setNavTheme("light");
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <HomePageIntroFrame>
        <DogLogo src={"/image/svg/logo.svg"} alt="Like A Dog With Two Tails" />
        <Title>MIKESIAN</Title>
        <SubTitle>DESIGN &amp; ART</SubTitle>
      </HomePageIntroFrame>
      <Projects
        featured={true}
        projectOrder={["Forza", "Above", "Egg", "Astroworld", "Take Refuge"]}
      />
      <ContactWrap>
        <Contact />
      </ContactWrap>
    </>
  );
};
