import React, { useState, useEffect, useRef } from "react";
import { ProjectDescriptionWrap, ProjectDescription } from "./styled";
import { useData } from "contexts/useData";
import { Project } from "../Project";
import { theme } from "../../styled";

interface ProjectDetailProps {
  match: any;
}

export const ProjectDetail: React.FC<ProjectDetailProps> = ({ match }) => {
  const { projects } = useData();
  const [project, setProject] = useState<any>();
  const backgroundStyle = useRef<any>();
  const { setNavTheme } = useData();

  useEffect(() => {
    setNavTheme("dark");
    setProject(
      projects.find(
        (project: any) =>
          project.title.toLowerCase() ===
          match.params.project
            .split("-")
            .join(" ")
            .toLowerCase()
      )
    );
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {project && (
        <>
          {/* Hero */}
          <Project
            title={project.title}
            media={project.hero}
            background="light"
          />

          <ProjectDescriptionWrap>
            <ProjectDescription>{project.description.text}</ProjectDescription>
          </ProjectDescriptionWrap>

          {project.media.map((media: any) => {
            if (media.imageSize !== "fill") {
              if (!backgroundStyle.current) {
                backgroundStyle.current = {
                  color: "light",
                  size: media.width
                };
              } else {
                if (
                  backgroundStyle.current.size !== "half" ||
                  media.width === "full"
                ) {
                  if (backgroundStyle.current.color === "dark") {
                    backgroundStyle.current = {
                      color: "light",
                      size: media.width
                    };
                  } else if (backgroundStyle.current.color === "light") {
                    backgroundStyle.current = {
                      color: "dark",
                      size: media.width
                    };
                  }
                }
              }
            }

            console.log(backgroundStyle);

            return (
              <Project
                key={media.src}
                title={project.title}
                media={media}
                background={
                  backgroundStyle.current && backgroundStyle.current.color
                }
              />
            );
          })}
        </>
      )}
    </>
  );
};
