import React from "react";
import Projects from "components/Projects";

interface DesignProps {}

export const DesignPage: React.FC<DesignProps> = () => {
  return (
    <>
      <Projects
        category={"design"}
        projectOrder={[
          "Taste It",
          "Take Refuge",
          "Astroworld",
          "Yesfest",
          "Above",
          "Forza",
          "There There Now",
          "Yestonbury",
          "Egg"
        ]}
      />
    </>
  );
};
