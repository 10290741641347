import { styled, css } from "../../styled";
import { NavLink, NavLinkProps } from "react-router-dom";

export const NavFrame = styled.header`
  position: absolute;
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const NavLinks = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  width: 250px;
  justify-content: center;
  padding: ${({ theme }) => theme.msrem(1)} 0;
`;

interface NavigationLinkProps extends NavLinkProps {
  navThemeProp?: string;
}

export const NavigationLink = styled(NavLink)<NavigationLinkProps>`
  font-weight: ${({ theme }) => theme.fonts.primary.weights.normal};
  text-decoration: none;
  font-size: ${({ theme }) => theme.msrem(-0.75)};
  margin: 0 ${({ theme }) => theme.ms(1) / 2}rem;

  ${props =>
    props.navThemeProp === "light" &&
    css`
      color: ${({ theme }) => theme.colors.light};
    `};

  ${props =>
    props.navThemeProp === "dark" &&
    css`
      color: ${({ theme }) => theme.colors.dark};
    `};

  &.is-active {
    text-decoration: line-through;
  }
`;
