import React, { useState, useEffect, useCallback, useRef } from "react";
import { useData } from "contexts/useData";
import { Project } from "components/Project";

interface ProjectsProps {
  featured?: boolean;
  category?: string;
  projectOrder: string[];
  context?: any;
  links?: boolean;
}
interface MediaType {
  type: string;
  title: string;
  src: string;
  poster: string;
  width: string;
  background: string;
  light: boolean;
  featured: boolean;
}
interface ProjectType {
  title: string;
  featured: boolean;
  category: string;
  description: {
    text: string;
    background: string;
  };
  hero: {
    type: string;
    title: string;
    src: string;
    background: string;
  };
  media: MediaType[];
  feature?: MediaType;
}

const Projects: React.FC<ProjectsProps> = ({
  featured,
  category,
  projectOrder,
  links = true
}) => {
  const { projects } = useData();
  const [projectList, setProjectList] = useState<any>([]);
  const { setNavTheme } = useData();
  const backgroundStyle = useRef<string>();

  useEffect(() => {
    setNavTheme("dark");
    if (projects) {
      setProjectList(applyFilter(projects));
    }
    //eslint-disable-next-line
  }, [projects]);

  const sortProjects = (array: [], order: string[], key: string) => {
    array.sort(function(a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  };

  const applyFilter = (projects: any) => {
    let result = projects;
    if (featured) {
      result = result.filter((project: ProjectType) => project.featured);
    }
    if (category) {
      result = result.filter(
        (project: ProjectType) => project.category === category
      );
    }
    result = sortProjects(result, projectOrder, "title");
    result = result.filter((project: ProjectType) => project.hero);

    return result;
  };

  const makePath = useCallback(
    project => {
      if (links) {
        return `/${project.category}/${project.title
          .split(" ")
          .join("-")
          .toLowerCase()}`;
      } else {
        return;
      }
    },
    [links]
  );

  return (
    <>
      {projectList &&
        projectList.map((project: any) => {
          if (project.hero.imageSize !== "fill") {
            if (!backgroundStyle.current) {
              backgroundStyle.current = "light";
            } else if (backgroundStyle.current === "dark") {
              backgroundStyle.current = "light";
            } else if (backgroundStyle.current === "light") {
              backgroundStyle.current = "dark";
            }
          }
          return (
            <Project
              title={project.title}
              media={project.hero}
              key={project.title}
              href={makePath(project)}
              background={backgroundStyle.current}
            />
          );
        })}
    </>
  );
};

export default Projects;
