import * as React from "react";
import { LogoWrap } from "./styled";
import { useData } from "contexts/useData";

const Logo: React.FC<{}> = () => {
  const { navTheme } = useData();

  return (
    <LogoWrap navThemeProp={navTheme} to="/">
      MIKESIAN
    </LogoWrap>
  );
};

export default Logo;
